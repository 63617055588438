<template>
  <b-row>
    <b-col cols="10">
      <b-row class="themes">
        <b-col>
          <b-overlay
            :show="isFetching || isSaving"
            spinner-variant="primary"
          >
            <b-card
              class="mb-0"
              no-body
            >
              <b-card-header>
                <b-card-title>
                  {{ $t('_navItem.Menu Designer') }}
                  <feather-icon
                    v-b-tooltip.hover="$t('themesInfo')"
                    icon="InfoIcon"
                    role="button"
                    size="20"
                    style="vertical-align: top"
                    class="ml-25 text-primary"
                  />
                </b-card-title>
              </b-card-header>
              <b-card-body>
                <app-collapse accordion>
                  <app-collapse-item
                    :title="`${$t('themesLabelZone')}`"
                    :is-visible="true"
                  >
                    <v-select
                      v-model="zone"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      label="name"
                      :options="optionsZone"
                    >
                      <template #no-options>
                        {{ $t('The list is empty') }}
                      </template>
                    </v-select>
                  </app-collapse-item>
                  <app-collapse-item :title="`${$t('themesPresets')}`">
                    <div class="themes__presets">
                      <div
                        v-for="preset in presets"
                        :key="preset.id"
                        :class="['themes__preset', getIsPresetActive(preset.id) ? 'themes__preset_active' : null]"
                        @click="setPreset(preset.id)"
                      >
                        <b-img-lazy
                          :src="require(`@/assets/images/themes/${preset.id}.png`)"
                          fluid
                          class="w-100"
                        />
                      </div>
                    </div>
                  </app-collapse-item>
                  <app-collapse-item :title="`${$t('themesFonts')}`">
                    <b-row>
                      <b-col>
                        <b-form-group :label="$t('themesLabelFontSecondary')">
                          <v-select
                            v-model="style.fontFamilySecondary"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :clearable="false"
                            label="title"
                            :options="optionsFontFamily"
                            @search:blur="updatePreviewSrc"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group :label="$t('themesLabelFontSecondarySize')">
                          <vue-slider
                            v-model="style.secondaryFontSizeCoefficient"
                            tooltip="none"
                            data-value="coefficient"
                            data-label="name"
                            :data="optionsFontSizeCoefficient"
                            :drag-on-click="true"
                            @drag-end="updatePreviewSrc"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group :label="$t('themesLabelFontPrimary')">
                          <v-select
                            v-model="style.fontFamilyPrimary"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :clearable="false"
                            label="title"
                            :options="optionsFontFamily"
                            @search:blur="updatePreviewSrc"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group :label="$t('themesLabelFontPrimarySize')">
                          <vue-slider
                            v-model="style.primaryFontSizeCoefficient"
                            tooltip="none"
                            data-value="coefficient"
                            data-label="name"
                            :data="optionsFontSizeCoefficient"
                            :drag-on-click="true"
                            @drag-end="updatePreviewSrc"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                  <app-collapse-item :title="`${$t('themesColors')}`">
                    <b-row>
                      <b-col>
                        <b-form-group :label="$t('themesLabelColorBackground')">
                          <b-form-input
                            v-model="style.colorBackground"
                            type="color"
                            @change="updatePreviewSrc"
                          />
                        </b-form-group>
                        <b-form-group :label="$t('themesLabelColorPrimary')">
                          <b-form-input
                            v-model="style.colorPrimary"
                            type="color"
                            @change="updatePreviewSrc"
                          />
                        </b-form-group>
                        <b-form-group :label="$t('themesLabelColorTextOnBackground')">
                          <b-form-input
                            v-model="style.colorTextOnBackground"
                            type="color"
                            @change="updatePreviewSrc"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group :label="$t('themesLabelColorCardBackground')">
                          <b-form-input
                            v-model="style.colorBackgroundCard"
                            type="color"
                            @change="updatePreviewSrc"
                          />
                        </b-form-group>
                        <b-form-group :label="$t('themesLabelColorSecondary')">
                          <b-form-input
                            v-model="style.colorSecondary"
                            type="color"
                            @change="updatePreviewSrc"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                  <app-collapse-item :title="`${$t('themesOtherSettings')}`">
                    <b-row>
                      <b-col cols="auto">
                        <div>
                          <b-form-radio
                            v-model="style.borderTypeCard"
                            value="all"
                            @change="updatePreviewSrc"
                          >
                            {{ $t('themesBorder') }}
                          </b-form-radio>
                          <b-form-radio
                            v-model="style.borderTypeCard"
                            value="bottom"
                            class="mt-25"
                            @change="updatePreviewSrc"
                          >
                            {{ $t('themesDivider') }}
                          </b-form-radio>
                        </div>
                        <div class="mt-2">
                          <div class="mb-25">
                            {{ $t('themesShadow') }}
                          </div>
                          <b-form-checkbox
                            v-model="style.isShadow"
                            switch
                            @change="updatePreviewSrc"
                          />
                        </div>
                      </b-col>
                      <b-col>
                        <div>
                          <div class="mb-25">
                            {{ $t('themesBorderWidth') }}
                          </div>
                          <div class="pl-50 pr-50">
                            <vue-slider
                              v-model="style.borderWidth"
                              tooltip="none"
                              :min="0"
                              :max="3"
                              :marks="true"
                              :hide-label="true"
                              :interval="1"
                              :drag-on-click="true"
                              @drag-end="updatePreviewSrc"
                            />
                          </div>
                        </div>
                        <div class="mt-1 pt-75">
                          <div class="mb-50">
                            {{ $t('themesRadius') }}
                          </div>
                          <div class="d-flex align-items-center">
                            <feather-icon icon="SquareIcon" />
                            <vue-slider
                              v-model="style.radiusCoefficient"
                              class="flex-grow-1 ml-1 mr-1"
                              tooltip="none"
                              :min="0"
                              :max="2"
                              :interval="0.01"
                              :drag-on-click="true"
                              @drag-end="updatePreviewSrc"
                            />
                            <feather-icon icon="CircleIcon" />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </app-collapse-item>
                </app-collapse>
                <div class="mt-2">
                  <b-button
                    variant="primary"
                    @click="onClickSave"
                  >
                    {{ $t('themesButtonSave') }}
                  </b-button>
                </div>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-col>
        <b-col>
          <template v-if="!isFetching">
            <iframe
              v-if="previewBase"
              class="themes__iframe"
              :src="previewUrl"
            />
            <b-card v-else>
              {{ $t('themesMessageNoPreview') }}
            </b-card>
          </template>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BImgLazy,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinThemes from '@/mixins/themes'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import UPDATE_RESTAURANT from '@/gql/mutation/restaurant/updateRestaurant.gql'

export default {
  name: 'Themes',
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BImgLazy,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BFormRadio,
    VueSlider,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinThemes,
  ],
  data() {
    return {
      presets: [
        {
          id: 1,

          fontFamilyPrimary: 'Montserrat',
          fontFamilySecondary: 'Montserrat',

          colorPrimary: '#ed4f2c',
          colorSecondary: '#232426',
          colorTextOnBackground: '#232426',
          colorBackground: '#f2f2f2',
          colorBackgroundCard: '#ffffff',

          primaryFontSizeCoefficient: 1,
          secondaryFontSizeCoefficient: 1,
          borderTypeCard: 'all',
          borderWidth: 0,
          radiusCoefficient: 1,
          isShadow: true,
        },
        {
          id: 2,

          fontFamilyPrimary: 'Roboto',
          fontFamilySecondary: 'Oswald',

          colorPrimary: '#2A276D',
          colorSecondary: '#2A276D',
          colorTextOnBackground: '#2A276D',
          colorBackground: '#E0D8CD',
          colorBackgroundCard: '#E0D8CD',

          primaryFontSizeCoefficient: 1,
          secondaryFontSizeCoefficient: 1,
          borderTypeCard: 'all',
          borderWidth: 1,
          radiusCoefficient: 0,
          isShadow: false,
        },
        {
          id: 3,

          fontFamilyPrimary: 'Fira Sans',
          fontFamilySecondary: 'Lora',

          colorPrimary: '#947A5A',
          colorSecondary: '#232321',
          colorTextOnBackground: '#232321',
          colorBackground: '#ffffff',
          colorBackgroundCard: '#ffffff',

          primaryFontSizeCoefficient: 1,
          secondaryFontSizeCoefficient: 1,
          borderTypeCard: 'bottom',
          borderWidth: 1,
          radiusCoefficient: 0.4,
          isShadow: false,
        },
        {
          id: 4,

          fontFamilyPrimary: 'Ubuntu',
          fontFamilySecondary: 'Ubuntu',

          colorPrimary: '#ffffff',
          colorSecondary: '#ffffff',
          colorTextOnBackground: '#ffffff',
          colorBackground: '#24242B',
          colorBackgroundCard: '#303037',

          primaryFontSizeCoefficient: 1,
          secondaryFontSizeCoefficient: 1,
          borderTypeCard: 'all',
          borderWidth: 0,
          radiusCoefficient: 1,
          isShadow: false,
        },
        {
          id: 5,

          fontFamilyPrimary: 'Poppins',
          fontFamilySecondary: 'Poppins',

          colorPrimary: '#000000',
          colorSecondary: '#000000',
          colorTextOnBackground: '#000000',
          colorBackground: '#ffffff',
          colorBackgroundCard: '#ffffff',

          primaryFontSizeCoefficient: 1,
          secondaryFontSizeCoefficient: 1,
          borderTypeCard: 'all',
          borderWidth: 2,
          radiusCoefficient: 0,
          isShadow: false,
        },
        {
          id: 6,

          fontFamilyPrimary: 'Montserrat',
          fontFamilySecondary: 'Dela Gothic One',

          colorPrimary: '#000000',
          colorSecondary: '#000000',
          colorTextOnBackground: '#000000',
          colorBackground: '#f8ece2',
          colorBackgroundCard: '#98cb2a',

          primaryFontSizeCoefficient: 1,
          secondaryFontSizeCoefficient: 1,
          borderTypeCard: 'all',
          borderWidth: 1,
          radiusCoefficient: 0.4,
          isShadow: false,
        },
      ],
      isFetching: true,
      isSaving: false,

      previewBase: '',
      previewUrl: '',

      optionsFontFamily: [
        'Ubuntu',
        'EB Garamond',
        'Noto Sans',
        'Roboto',
        'Montserrat',
        'Playfair Display',
        'Oswald',
        'Dela Gothic One',
        'Yeseva One',
        'Caveat',
        'Balsamiq Sans',
        'Fira Sans',
        'Lora',
        'Poppins',
      ],
      optionsFontSizeCoefficient: [
        {
          id: 1,
          name: 'S',
          coefficient: 0.9,
        },
        {
          id: 2,
          name: 'M',
          coefficient: 1,
        },
        {
          id: 3,
          name: 'L',
          coefficient: 1.1,
        },
        {
          id: 5,
          name: 'XL',
          coefficient: 1.3,
        },
        {
          id: 6,
          name: 'XXL',
          coefficient: 1.5,
        },
      ],

      style: {
        fontFamilyPrimary: '',
        fontFamilySecondary: '',

        colorPrimary: '#000000',
        colorSecondary: '#000000',
        colorTextOnBackground: '#000000',
        colorBackground: '#ffffff',
        colorBackgroundCard: '#ffffff',

        primaryFontSizeCoefficient: 1,
        secondaryFontSizeCoefficient: 1,
        borderTypeCard: 'all',
        borderWidth: 0,
        radiusCoefficient: 1,
        isShadow: true,
      },

      zone: null,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      isZonesFetched: 'floorPlan/isZonesFetched',
      zones: 'floorPlan/zones',
    }),
    colorPrimaryRgb() {
      const rgb = this.getRgbFromHex(this.style.colorPrimary)
      return `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`
    },
    colorPrimaryAdditional() {
      const colors = this.calcColors(
        this.getMathFunction(1)[0][1],
        this.style.colorPrimary,
        this.style.colorBackgroundCard,
        20,
        1,
      )
      return colors[16][0].substring(1)
    },
    colorSecondaryAdditional() {
      const colors = this.calcColors(
        this.getMathFunction(1)[0][1],
        this.style.colorSecondary,
        this.style.colorBackground,
        69,
        1,
      )
      return colors[25][0].substring(1)
    },
    colorSecondaryAdditionalRgb() {
      const rgb = this.getRgbFromHex(`#${this.colorSecondaryAdditional}`)
      return `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`
    },
    colorSecondaryAdditional2() {
      const colors = this.calcColors(
        this.getMathFunction(5)[2][1],
        this.style.colorSecondary,
        this.style.colorBackground,
        80,
        5,
      )
      return colors[77][0].substring(1)
    },
    colorBackgroundRgb() {
      const rgb = this.getRgbFromHex(this.style.colorBackground)
      return `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`
    },
    previewQuery() {
      let query = ''

      query = `${query}?fontFamilyPrimary=${this.style.fontFamilyPrimary}`
      query = `${query}&fontFamilySecondary=${this.style.fontFamilySecondary}`

      query = `${query}&colorPrimary=${this.style.colorPrimary.substring(1)}`
      query = `${query}&colorPrimaryRgb=${this.colorPrimaryRgb}`
      query = `${query}&colorPrimaryAdditional=${this.colorPrimaryAdditional}`

      query = `${query}&colorSecondary=${this.style.colorSecondary.substring(1)}`
      query = `${query}&colorSecondaryAdditional=${this.colorSecondaryAdditional}`
      query = `${query}&colorSecondaryAdditionalRgb=${this.colorSecondaryAdditionalRgb}`
      query = `${query}&colorSecondaryAdditional2=${this.colorSecondaryAdditional2}`

      query = `${query}&colorTextOnBackground=${this.style.colorTextOnBackground.substring(1)}`

      query = `${query}&colorBackground=${this.style.colorBackground.substring(1)}`
      query = `${query}&colorBackgroundRgb=${this.colorBackgroundRgb}`

      query = `${query}&colorBackgroundCard=${this.style.colorBackgroundCard.substring(1)}`

      query = `${query}&primaryFontSizeCoefficient=${this.style.primaryFontSizeCoefficient}`
      query = `${query}&secondaryFontSizeCoefficient=${this.style.secondaryFontSizeCoefficient}`
      query = `${query}&borderTypeCard=${this.style.borderTypeCard}`
      query = `${query}&borderWidth=${this.style.borderWidth}`
      query = `${query}&radiusCoefficient=${this.style.radiusCoefficient}`
      query = `${query}&isShadow=${this.style.isShadow}`

      return query
    },
    optionsZone() {
      return this.zones.filter(i => i.menus.length)
    },
    styleKeys() {
      return Object.entries(this.style).map(i => i[0])
    },
  },
  watch: {
    async zone(newValue) {
      await this.setPreviewBase(newValue)
      this.updatePreviewSrc()
    },
    restaurant() {
      this.handleDependencies()
      this.fetch()
    },
    isZonesFetched() {
      this.fetch()
    },
  },
  created() {
    this.handleDependencies()
    this.fetch()
  },
  methods: {
    ...mapActions({
      getZones: 'floorPlan/getZones',
      updateRestaurant: 'restaurant/updateRestaurant',
      shortenPmenusRequest: 'main/shortenPmenusRequest',
    }),
    handleDependencies() {
      if (!this.restaurant) {
        return
      }

      let getActions = []
      if (!this.isZonesFetched) {
        getActions = [...getActions, 'getZones']
      }

      getActions.map(getAction => this[getAction]())
    },

    async onClickSave() {
      this.isSaving = true

      const digitalMenuSettings = {
        fontFamilyPrimary: this.style.fontFamilyPrimary,
        fontFamilySecondary: this.style.fontFamilySecondary,

        colorPrimary: this.style.colorPrimary.substring(1),
        colorPrimaryRgb: this.colorPrimaryRgb,
        colorPrimaryAdditional: this.colorPrimaryAdditional,

        colorSecondary: this.style.colorSecondary.substring(1),
        colorSecondaryAdditional: this.colorSecondaryAdditional,
        colorSecondaryAdditionalRgb: this.colorSecondaryAdditionalRgb,
        colorSecondaryAdditional2: this.colorSecondaryAdditional2,

        colorTextOnBackground: this.style.colorTextOnBackground.substring(1),

        colorBackground: this.style.colorBackground.substring(1),
        colorBackgroundRgb: this.colorBackgroundRgb,

        colorBackgroundCard: this.style.colorBackgroundCard.substring(1),

        primaryFontSizeCoefficient: this.style.primaryFontSizeCoefficient,
        secondaryFontSizeCoefficient: this.style.secondaryFontSizeCoefficient,
        borderTypeCard: this.style.borderTypeCard,
        borderWidth: this.style.borderWidth,
        radiusCoefficient: this.style.radiusCoefficient,
        isShadow: this.style.isShadow,
      }

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: {
            updateRestaurantId: this.restaurant.id,
            digitalMenuSettings,
          },
        })

        this.updateRestaurant({ digitalMenuSettings })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            text: this.$t('notificationSuccessSaved'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isSaving = false
      }
    },
    async fetch() {
      if (!this.restaurant || !this.isZonesFetched) {
        return
      }

      if (this.restaurant.digitalMenuSettings) {
        const isEveryStyleValueInResponse = this.styleKeys
          .map(i => this.restaurant.digitalMenuSettings[i])
          .every(i => i !== undefined)
        if (isEveryStyleValueInResponse) {
          this.styleKeys.forEach(i => {
            const value = this.restaurant.digitalMenuSettings[i]
            this.style[i] = i.startsWith('color') ? `#${value}` : value
          })
        } else {
          this.setPreset(1)
        }
      } else {
        this.setPreset(1)
      }

      if (this.optionsZone.length) {
        this.zone = this.optionsZone[this.optionsZone.length - 1]
      }

      this.isFetching = false
    },
    async setPreviewBase(zone) {
      const code = await this.shortenPmenusRequest([this.restaurant.id, zone.id])
      this.previewBase = `${process.env.VUE_APP_ORIGIN_CLIENT}/${code}/catalog`
      // this.previewBase = `http://localhost:8081/${code}/catalog`
    },

    updatePreviewSrc() {
      this.previewUrl = `${this.previewBase}${this.previewQuery}`
    },
    setPreset(presetId) {
      const preset = this.presets.find(i => i.id === presetId)
      if (!preset) {
        return
      }

      this.styleKeys.forEach(i => {
        this.style[i] = preset[i]
      })

      this.updatePreviewSrc()
    },
    getIsPresetActive(presetId) {
      const preset = this.presets.find(i => i.id === presetId)
      if (!preset) {
        return false
      }

      return this.styleKeys.every(i => (this.style[i] === preset[i]))
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/libs/vue-slider.scss'
  @import '@core/scss/vue/libs/vue-select.scss'

  .themes
    --themes-preset-mt: 4px
    --themes-preset-mr: 12px
    --themes-presets-in-row: 3

    // .content.app-content: padding-top, value
    --iframe-top-indent: calc(1.3rem + #{$navbar-height} + 2rem)
    &__iframe
      display: block
      width: 100%
      height: max(calc(100vh - var(--iframe-top-indent) - #{$footer-height}), 600px)
      border: none
      border-radius: $card-border-radius
      box-shadow: $box-shadow
    &__presets
      display: flex
      flex-wrap: wrap
    &__preset
      width: calc(100% / var(--themes-presets-in-row) - (var(--themes-preset-mr) * calc(var(--themes-presets-in-row) - 1) / var(--themes-presets-in-row)))
      margin: var(--themes-preset-mt) var(--themes-preset-mr) var(--themes-preset-mt) 0
      cursor: pointer
      &:nth-child(3n) // --themes-presets-in-row
        margin-right: 0
      .img-fluid
        border: 1px solid $border-color
        border-radius: $border-radius
      &_active
        .img-fluid
          border-color: $primary
</style>
